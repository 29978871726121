import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BlockRouter from '../components/BlockRouter';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Layout from '../components/Layout';
import PageDescription from '../components/PageDescription';
import PageTitle from '../components/PageTitle';


const FamilyPage = ({ data, location, pageContext }) => {
  const { languageSwitch, locale, slug } = pageContext;
  const footerData = data.allDatoCmsFooter.edges[0].node;
  const family = data.allDatoCmsFamily.edges[0].node;
  const { body, description, gradient, name, seoMetaTags } = family;

  // gatsby-source-datocms can't handle the union field generated by having
  // two different model types in the internalLink field, so we have to fetch
  // this from Dato's native GraphQL API instead, which requires some data
  // munging in order to get everything in the correct locale.
  const internalLinks = data.cms.family._allBodyLocales.find((b) => b.locale === locale).value; // eslint-disable-line no-underscore-dangle
  body.forEach((b, idx) => {
    if (internalLinks[idx].internalLink) {
      b.internalLink = {
        slug: internalLinks[idx].internalLink._allSlugLocales.find((s) => s.locale === locale).value, // eslint-disable-line no-underscore-dangle
      };
    }
  });

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <PageDescription>{description}</PageDescription>
        <For each="block" index="index" of={body}>
          <BlockRouter key={block.id} block={block} index={index} slug={slug} />
        </For>
      </GradientWrapper>
    </Layout>
  );
};

FamilyPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};


export default FamilyPage;

export const query = graphql`
  query FamilyPage($locale: String!) {
    allDatoCmsFamily(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          description
          name
          locale
          slug
          gradient {
            color {
              hex
            }
          }
          ...GatsbyDatoCmsFamilyBody
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
    # Union fields
    cms {
      family {
        _allBodyLocales {
          locale
          value {
            ... on DatoCms_ImageMarkdownCenteredRecord {
              internalLink {
                ... on DatoCms_JobsPageRecord {
                  _allSlugLocales {
                    locale
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
